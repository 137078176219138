<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    />
    <v-layout align-center justify-center>
      <div class="size">
        Select Your Grade Level
      </div>
    </v-layout>
    <br>
    <!--  <divide-content /> -->
    <br>
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics1"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.title"
            :description="topic.description"
            :img="topic.img"
            :path="topic.link"
          />
        </v-flex>
      </v-layout>
      <br><br>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics2"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.title"
            :description="topic.description"
            :img="topic.img"
            :path="topic.link"
          />
        </v-flex>
      </v-layout>
      <br><br>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics3"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.title"
            :description="topic.description"
            :img="topic.img"
            :path="topic.link"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import axios from 'axios';
import SubtopicCard from './SubTopics.vue'
//import DivideContent from './Divider.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'GradesChem',
    components: {
    SubtopicCard
    },
    metaInfo: {
          title: "Explore Chemistry",
          titleTemplate:"%s | Learn interactively",
          meta: [
                {vmid:"description", name:"description", content:"Learn fundamentals of chemistry using visually interactive illustrations"},
                {vmid:"keywords", name:"keywords", content: "fundamentals of chemistry, high-school chemistry, demos in chemistry, visually interactive illustrations"},
                { vmid:"chars", charset: "utf-8" },
                { vmid:"viewport", name: "viewport", content:"width=device-width, initial-scale=1"}
                ],
          link: [{rel:"canonical", href:"https://edliy.com/gradesChem"}]
             },
    data () {
    return {
      style: {layout: "margin: 10px"},
      gradesList:[],
      topics1:[
      { title: "6th Grade",
        description: "Chemistry",
        img: "/assets/Chemistry-6th.svg",
        link: "/chemistry6th"
      },
      { title: "7th Grade",
        description: "Chemistry",
        img: "/assets/Chemistry-7th.svg",
        path: "/chemistry7th"
      },
      {
        title: "8th Grade",
        description: "Chemistry",
        img: "/assets/Chemistry-8th.svg",
        link: "/chemistry8th"
      }
    ],
      topics2:[
      {
        title: "9th Grade",
        description: "Chemistry",
        img: "/assets/Chemistry-9th.svg",
        link: "/chemistry9th"
      }
      ],
      topics3: [
      {
        title: "10th Grade",
        description: "Chemistry",
        img:"/assets/Chemistry-10th.svg",
        link: "/chemistry10th"
      },
      { title: "11th Grade",
        description: "Chemistry",
        img: "/assets/Chemistry-11th.svg",
        link: "/chemistry11th"
      },
      { title: "12th Grade",
        description: "Chemistry",
        img: "/assets/Chemistry-12th.svg",
        link: "/chemistry12th"
      }
    ]
    }
  },
    created () {
    this.$store.commit('navigation/resetState');
    //this.$store.dispatch('navigation/getData');
    this.$store.commit('navigation/changeTitle', 'Explore Chemistry');
  //  this.$store.commit('navigation/changeMenu', 'Explore Chemistry');
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceChem', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
  },
  /*computed:
  {
    ...mapGetters(
      {
      showhome: 'navigation/showhome',
      showMath: 'navigation/showMath',
      showPhys: 'navigation/showPhys',
      showChem: 'navigation/showChem'
      })
  },*/
  async mounted () {
    console.log("id::",this.$route.query.id)
    console.log("logged in??", localStorage.getItem('token'))
  //  if(localStorage.getItem('token')){
    try {
        console.log("id::",this.$route.query.id)
        if(this.$route.query.id !== undefined)
          {
          const response = await axios.get(process.env.VUE_APP_API+'category/'+this.$route.query.id+'/courses');
            if(response.data.success)
            {
            this.gradesList = response.data.data.category.courses;
              for(let i in this.gradesList)
              { let j = 6+ +i;
                this.gradesList[i].link = this.gradesList[i].link+'?grade='+this.gradesList[i].id;
                this.gradesList[i].img ='/assets/Chemistry-'+j+'th.svg'
              }
            this.topics1=this.gradesList.slice(0,3);
            this.topics2=this.gradesList.slice(3,4);
            this.topics3=this.gradesList.slice(4,8);
            }
          }
        }
    catch (error) {
      console.error(error);
      this.$notify({
                group: 'notify',
                type: 'error',
                title: 'You are not logged in!',
                text: 'Log in to get full access.'
      });
    }
  //  }
  }
  }
  </script>
  <style scoped lang="scss">
  @import '@/styles/screen-sizes.scss';
  </style>
